import React from "react";
import classnames from "classnames";
import { Modal, Toast } from "antd-mobile";
import { BaseLayout } from "components";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { isIOS, handleDownloadProcess, excuteDownload } from "utils";
import fetchJsonp from "fetch-jsonp";
import { linkConfig, webCfg } from "config";
import styles from "./styles.module.scss";
import queryString from "query-string";
const Alert = Modal.alert;

const HiddenDownloadInfo = () => (
  <div id="download-info" style={{ display: "none" }}>
    <span>pooling download urls</span>
    {linkConfig.download.androidUrl.ytjy.map((url, i) => (
      <span id={`url-${i}`} key={i}>
        {url}
      </span>
    ))}
    <span id={"backup-url"}>{linkConfig.download.androidUrl.ytjy_backup}</span>
  </div>
);

class DownloadKLZT extends React.PureComponent {
  state = {
    androidUrl: linkConfig.download.androidUrl["klzt"],
    androidUrlBackup: linkConfig.download.androidUrl["klzt_backup"] || "",
    // iosUrl: linkConfig.download.ios["ytjy"],
    loading: false,
    title: "立即下载",
    slogan: "",
    platform: "klzt",
    detail: "https://www.woyaotf.cn/app_merge/BTHQ",
    copy: false,
    step: false,
  };
  constructor(props) {
    super(props);
    this.$download = React.createRef();
    this.$copy = React.createRef();
  }

  componentDidMount() {
    if (window.location.search) {
      const params = queryString.parse(window.location.search);
      if (
        params.platform &&
        params.title &&
        params.url &&
        Object.keys(linkConfig.download.ios).includes(params.platform)
      ) {
        this.setState({
          androidUrl: params.url,
          slogan: params.title,
          platform: params.platform,
          detail: `${this.state.detail}${window.location.search}`,
        });
      }
    } else {
      // this.getUrl();
    }
    var link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.head.appendChild(link);
    }
    link.href = "/download/ytjy/ico.png";
  }
  getUrl = async () => {
    const res = await fetchJsonp(linkConfig.download.android);
    const ret = await res.json();
    if (ret.code === "success") {
      this.setState({
        androidUrl: ret.data.url,
      });
    }
  };
  startDownload = async () => {
    this.setState({
      loading: true,
      title: "获取中...",
      // step: isIOS() ? true : false,
      step: false,
    });
    // const ua = window.navigator.userAgent.toLowerCase();
    // const isWeixinBrowser = !!ua.match(/MicroMessenger/i);
    // const isWhiteBrowser = !!ua.match(/MQQBrowser|Safari|UCBrowser/i);
    this.$download.current && this.$download.current.click();
    // if (!isWhiteBrowser && isIOS() && !isWeixinBrowser) {
    //   this.setState({
    //     copy: true,
    //   });
    //   Alert("请在Safair浏览器中打开此页面并再次点击“立即下载”按钮", "", [
    //     { text: "取消" },
    //     {
    //       text: "复制链接",
    //       onPress: this.copy,
    //     },
    //   ]);
    //   return;
    // }

    let urls = this.state.androidUrl;
    let backUrl = this.state.androidUrlBackup;
    try {
      await handleDownloadProcess(urls);
    } catch (e) {
      backUrl && excuteDownload(backUrl);
    } finally {
      this.$timer = setTimeout(() => {
        this.setState({
          loading: false,
          title: "立即下载",
        });
        clearTimeout(this.$timer);
      }, 3000);
    }
  };
  copy = () => {
    this.$copy.current && this.$copy.current.click();
    Toast.success("复制成功！", 1);
  };
  hideDetail = () => {
    this.setState({
      step: false,
    });
  };
  componentWillUnmount() {
    clearTimeout(this.$timer);
  }
  render() {
    const ua = window.navigator.userAgent.toLowerCase();
    const isWeixinBrowser = !!ua.match(/MicroMessenger/i);
    // const AppStoreUrl = this.state.iosUrl;
    return (
      <BaseLayout>
        <div
          className={classnames("full-screen", styles.ytjy, {
            [styles.fixed_scroll]: this.state.step || this.state.detail,
          })}
        >
          {isWeixinBrowser ? (
            <div
              className={classnames(styles.shadow, {
                [styles.android]: !isIOS(),
              })}
            />
          ) : null}

          <img
            className={styles.sample}
            alt="sample"
            src="/download/klzt/sample.png"
          />

          <img
            className={styles.logo}
            alt="logo"
            src="/download/klzt/logo.png"
          />

          <div className="flex-container flex-space-between layout-vertical">
            {/* {isIOS() ? (
              <React.Fragment>
                <a
                  href={AppStoreUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classnames(styles.download_btn, {
                    [styles.loading]: this.state.loading,
                  })}
                >
                  <span className={styles.ios_icon} />
                  App Store
                </a>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div
                  onClick={this.startDownload}
                  ref={this.$download}
                  className={classnames("flex-block", styles.download_btn, {
                    [styles.loading]: this.state.loading,
                  })}
                >
                  立即下载
                  <span className="label" />
                </div>
              </React.Fragment>
            )} */}
            <React.Fragment>
              <div
                onClick={this.startDownload}
                ref={this.$download}
                className={classnames("flex-block", styles.download_btn, {
                  [styles.loading]: this.state.loading,
                })}
              >
                立即下载
                <span className="label" />
              </div>
            </React.Fragment>
          </div>

          {this.state.copy ? (
            <CopyToClipboard
              className="visible-hide"
              text={`${window.location.origin}/download${window.location.search}`}
            >
              <i ref={this.$copy} />
            </CopyToClipboard>
          ) : null}

          <HiddenDownloadInfo />

          <div className={classnames("tips", styles.tips)}>
            Copyrights © {new Date().getFullYear()}{" "}
            {webCfg[this.state.platform].copyright}
            版权所有不得转载
          </div>
        </div>
      </BaseLayout>
    );
  }
}

export default DownloadKLZT;
